import * as LOCALS from './constants';
import { ILocals } from './types';

const hangarLocals: ILocals = {
  [LOCALS.SELECT_PLACE_TEXT]: 'Wybierz aktywność',
  [LOCALS.SELECT_PLAYERS_TEXT]: 'Wybierz liczbę osób',
  [LOCALS.REMEMBERS_TEXT]:
    'Pamiętaj żeby przyjść 15 min. wcześniej (rejestracja + przebranie się).',
  [LOCALS.RESERVATION_TEXT]: 'Podaj dane do rezerwacji',
  [LOCALS.INTRO_RESERVATION_TEXT]: 'Rezerwacja',
  [LOCALS.EMPTY_SLOTS_TEXT]:
    'Brak wolnych slotów na tę godzinę. Spróbuj zmienić typy biletów lub wybierz inny termin.',
};

export default hangarLocals;
